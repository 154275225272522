import React, { useContext, useEffect, useState } from 'react';
import NavbarShift from './Components/Navbar/NavbarShift';
import Login from './Pages/Login/Login';
import { SnackbarProvider } from 'notistack';
import { UserContext } from './Context/userContext';
import { Navigate, Route, Routes } from 'react-router-dom';
import Page404 from './Components/Page404/erreur404';
import Dashboard from './Pages/Dashboard/Dashboard';

function App() {
  const {currentUser, user, logOut} = useContext(UserContext);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    if(!user || user?.status !== 1){
      logOut();
    }
  },[currentUser, user])
  return (
    <>
      <SnackbarProvider maxSnack={1} anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}>
    {currentUser && <NavbarShift setCurrentDate={setCurrentDate}/>}

      <Routes>
        <Route path="/" element={currentUser ? <Navigate to="/Dashboard" />: <Login />}/>
        <Route path="/Dashboard" element={currentUser ? <Dashboard currentDate={currentDate} setCurrentDate={setCurrentDate}/>: <Navigate to="/" />}/>
        <Route path='*' element={<Page404 />} />
      </Routes>
    </SnackbarProvider>
    </>

  );
}

export default App;
