import React, { useContext, useRef } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../Context/userContext';
export default function Login() {

    const { enqueueSnackbar } = useSnackbar();
    const { signIn } = useContext(UserContext);
    const inputs = useRef([]);
    const addInputs = (el) => {
      if (el && !inputs.current.includes(el)) {
        inputs.current.push(el);
      }
    };

    const handleForm = async (e) => {
      e.preventDefault();
      try {
        await signIn(
          inputs.current[0].value,
          inputs.current[1].value
        );
      } catch {
        enqueueSnackbar(`Le mail / mot de passe incorrect`, {
          variant: 'error',
          style:{fontSize: '1.2rem'},
          anchorOrigin: { vertical: 'bottom', horizontal: 'center'},
          autoHideDuration: 3000,
        });
      }
    };

  return (
    <div style={{ background: '#000', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>     
        <Container className="" >
            <h1 className="text-light p-3 pb-5 text-center">TimeTide By <span style={{color: '#008080'}}>CLEAMY</span></h1>
            <Row className="" >
                <Col lg={5} md={6} sm={12} className="p-5 m-auto shadow-sm rounded-lg">
                    <form onSubmit={handleForm}>
                        <Form.Group role="form">
                            <Form.Label className='text-light' style={{fontSize: '1.4rem'}}>Email </Form.Label>
                            <Form.Control type="email" placeholder="Enter email" required style={{fontSize: '1.4rem'}} ref={addInputs}/>

                            <Form.Label className='text-light mt-4' style={{fontSize: '1.4rem'}}>Mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Password" required style={{fontSize: '1.4rem'}} ref={addInputs}/>

                            <div className="d-grid gap-2 mt-5">
                                <Button variant="btn-block" className='text-light' style={{background: '#008080', fontSize: '1.6rem'}} type="submit">
                                    Login
                                </Button>
                            </div>
                        </Form.Group>
                    </form>
                </Col>
            </Row>
            <h6 className="mt-5 p-5 text-center text-secondary fixed-bottom">Copyright © 2022 Cleamy. All Rights Reserved.</h6>
        </Container>
    </div>
  )
}
