import { db } from "../firebase-config";
import { onSnapshot, collection } from "firebase/firestore";

const shiftsCollection = collection(db, 'Shifts');

class ShiftsDataService {

  getShifts(setShiftData) {
    const unsubscribe = onSnapshot(shiftsCollection, (snapshot) => {
        const newShifts = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        setShiftData(newShifts);
    });
    
    return unsubscribe;
  }

}

export default new ShiftsDataService();