import { db } from "../firebase-config";
import { onSnapshot, collection } from "firebase/firestore";

const hubsCollection = collection(db, 'Hubs');

class HubsDataService {

  getHubs(setHubsData) {
    const unsubscribe = onSnapshot(hubsCollection, (snapshot) => {
        const newShifts = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        setHubsData(newShifts);
    });
    
    return unsubscribe;
  }

}

export default new HubsDataService();