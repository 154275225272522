import img404 from "../../Images/404.png";
import React from 'react';
import {useNavigate} from "react-router-dom";

function Page404() {
    const navigate = useNavigate();
    const onClick = () => {
        navigate("/")
    }
    return(
        <div className="container-fluid text-center" style={{backgroundColor:'#FFF', height:"100vh"}}>
            <h1 className="pt-5">Nous sommes désolés, mais la page que vous recherchez est introuvable ou n'existe pas</h1>
            <div className="d-grid gap-2 col-md-3 mx-auto text-center">
                <button type="button" className="btn btn-info p-3" style={{fontSize: "1.2rem", color:'white'}} onClick={onClick}>Page principale</button>
            </div>
            <img src={img404} className="img-fluid d-block rounded mx-auto" alt="présente l'erreur 404 d'une page qui n'existe pas"></img>
        </div>
    )
}
export default Page404