
import React, { useContext, useEffect, useState } from 'react';
import { MdToday } from 'react-icons/md';
import { Button, Container, Navbar, Offcanvas } from 'react-bootstrap';
import { UserContext } from '../../Context/userContext';
import DriversDataService from '../../Context/Drivers.services';
import './NavbarShift.css';

export default function NavbarShift(props) {
  const { currentUser, logOut } = useContext(UserContext);
  const [customerData, setCustomerData] = useState(null);

  const capitalizeName = (name) => {
    if (!name) return ""; 
    return name
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  }

  const resetToDate = () => {
    props.setCurrentDate(new Date());
  }
  useEffect(() => {
    const unsubscribe = DriversDataService.getDriver(currentUser.uid, setCustomerData);
    
      return () => unsubscribe(); 
}, []);


  return (
    <>
   
        <Navbar  expand={false} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} className='button_burger'/>
            <MdToday color="#008080" className='icon_date' onClick={resetToDate}/>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${false}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
              placement="start"
            >
              <Offcanvas.Header closeButton >
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`} className='text-1'>
                  TideTime By CLEAMY
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              {currentUser && customerData && (
                <>
                <div className="justify-content-end flex-grow-1 pe-3 mb-3">
                  <p className='m-0 p-0' style={{fontWeight: '500px'}}>{capitalizeName(customerData?.firstName)} {customerData?.lastName.toUpperCase()}</p>
                  <p className='m-0 p-0 fw-lighter fst-italic'>{customerData?.email}</p>
                </div>
                <Button variant="danger" className='px-3 mt-2' style={{fontSize: '1.6rem'}} onClick={() => logOut()}>Se déconnecter</Button>
                </>
              )}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
    </>
  )
}
