import { db } from "../firebase-config";
import { onSnapshot, doc } from "firebase/firestore";

class DriversDataService {

  getDriver(id, setCustomerData) {
    const unsubscribe = onSnapshot(doc(db, "Drivers", id), (doc) => {
      setCustomerData(doc.data());
    });
    
    return unsubscribe;
  }

}

export default new DriversDataService();