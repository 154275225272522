import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaAngleLeft, FaAngleRight, FaArrowLeft, FaMapMarkerAlt, FaThumbsUp, FaUser } from 'react-icons/fa';
import DriversDataService from '../../Context/Drivers.services';
import { UserContext } from '../../Context/userContext';
import './Dashboard.css';
import { collection, query, onSnapshot, updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import ShiftsDataServices from '../../Context/Shifts.services';
import HubsDataServices from '../../Context/Hubs.services';
import { MdCancel, MdCheck, MdCheckCircle } from 'react-icons/md';
import { HiHand, HiOutlineFingerPrint } from 'react-icons/hi';
import { useSnackbar } from 'notistack';

export default function Dashboard(props) {

    const { currentUser } = useContext(UserContext);
    const [customerData, setCustomerData] = useState(null);
    const [visibleDates, setVisibleDates] = useState([]);
    const dateContainerRef = useRef(null);
    const [tasks, setTasks] = useState({});  // Nouveau state pour les tâches
    const [shifts, setShifts] = useState([]);
    const [hubs, sethubs] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const moveBackward = () => {
        const today = new Date(props.currentDate);
        today.setHours(0, 0, 0, 0);
        const created_at_date = new Date(customerData?.created_at?.toDate());
        created_at_date.setHours(0, 0, 0, 0);
        if (today > created_at_date) {
            const newDate = new Date(props.currentDate);
            newDate.setDate(newDate.getDate() - 1);
            props.setCurrentDate(newDate);
          }
    };
    const moveForward = () => {
        const newDate = new Date(props.currentDate);
        newDate.setDate(newDate.getDate() + 1);
        props.setCurrentDate(newDate);
    };
    const updateVisibleDates = () => {
        const generateDates = (numberOfDates) => {
            let dates = [];
            let baseDate = new Date(props.currentDate);
            for (let i = 0; i < numberOfDates; i++) {
                dates.push(new Date(baseDate));
                baseDate.setDate(baseDate.getDate() + 1);
            }
            return dates;
        }
    
        if (dateContainerRef.current) {
            const containerWidth = dateContainerRef.current.offsetWidth;
            const dateWidth = 200; // Fixe la largeur à 200px
            const numberOfDates = Math.floor(containerWidth / dateWidth); // Divise la largeur du conteneur par 200 pour déterminer combien de colonnes peuvent être visibles
            const newDates = generateDates(numberOfDates);
            setVisibleDates(newDates);
            // Mettez à jour les tâches pour les nouvelles dates visibles
            fetchTasksForVisibleDates(newDates);
        }
    };
    const fetchTasksForVisibleDates = (dates) => {

        let newTasks = {};
    
        const fetchShiftsForDate = async (date) => {
            // Mettez en forme votre date en tant que début et fin de la journée
            const startOfDay = new Date(date);
            startOfDay.setHours(0, 0, 0, 0);
    
            const endOfDay = new Date(date);
            endOfDay.setHours(23, 59, 59, 999);
    
            // Obtenez tous les documents
            const q = query(collection(db, 'Planning'));
            
            onSnapshot(q, (snapshot) => {
                newTasks[date.toDateString()] = [];
    
                snapshot.docs.forEach((doc)  => {
                    const data = doc.data();
                    data.id = doc.id;
              
                    // Vérifiez si le document contient des shifts
                    if (data.shifts && Array.isArray(data.shifts)) {
                        // Filtrer les shifts pour cette date spécifique et les ajouter aux tâches
                        // const shiftsForDate = data.shifts.filter((shift, index) => {
                        //     const shiftDate = shift.date.toDate();
                        //     return shiftDate >= startOfDay && shiftDate <= endOfDay;
                        // });
                        const shiftsForDate = data.shifts
                        .map((shift, index) => ({ ...shift, index_shift: index })) // Ajoutez le champ ind ici
                        .filter(shift => {
                            const shiftDate = shift.date?.toDate();
                            return shiftDate >= startOfDay && shiftDate <= endOfDay;
                        });
         
                        newTasks[date.toDateString()] = [
                            ...newTasks[date.toDateString()],
                            ...shiftsForDate.reduce((acc, shift) => {
                                return acc.concat(
                                    shift.shifts_available?.map((shift_available, availableIndex) => ({
                                        ...shift_available,
                                        in_progress: data.in_progress,
                                        id_doc: data.id,
                                        index_shift: shift.index_shift,
                                        index_available_shift: availableIndex,
                                        dateDebutPlannig: data.start
                                    })) || []
                                );
                            }, []),
                        ];
                        
                    }
                });
                
                // Mettez à jour l'état une fois que tous les documents ont été vérifiés
                setTasks({ ...newTasks });
               
            });
        };
    
        dates.forEach(date => {
            fetchShiftsForDate(date);
        });
    }
    const capitalizeName = (name) => {
        if (!name) return ""; 
        return name
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
      }

    useEffect(() => {
        const unsubscribe = DriversDataService.getDriver(currentUser.uid, setCustomerData);
        
          return () => unsubscribe(); 
    }, []);
    useEffect(() => {
        updateVisibleDates();
  
        window.addEventListener('resize', updateVisibleDates);
  
        return () => {
            window.removeEventListener('resize', updateVisibleDates);
        };
    }, [props.currentDate]);
    useEffect(() => {
        const unsubscribe = ShiftsDataServices.getShifts(setShifts)

        // N'oubliez pas de se désabonner lors du nettoyage pour éviter les fuites de mémoire
        return () => unsubscribe();
    }, []);
    useEffect(() => {
        const unsubscribe = HubsDataServices.getHubs(sethubs);

        // N'oubliez pas de se désabonner lors du nettoyage pour éviter les fuites de mémoire
        return () => unsubscribe();
    }, []);


    const handleHandClick = async (task, index, index2) => {
        console.log(index);
        console.log(index2);
        //Créez une référence au document
        const docRef = doc(db, "Planning", task.id_doc);
        // Obtenez le document actuel
        const docSnap = await getDoc(docRef);
        
        const newTask = {
            ...docSnap.data()
        }
        // Construisez le nouveau driver à ajouter
            const newDriver = { 
                id: currentUser.uid, 
                to_apply: false
            };
        newTask.shifts[index].shifts_available[index2].drivers.push(newDriver);
        // Mettez à jour le document avec le tableau de drivers mis à jour
            await updateDoc(docRef, {
                shifts: newTask.shifts
            });
            enqueueSnackbar('Votre candidature au planning a été enregistrée avec succès.', { 
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                className: 'text-center',
                style: {fontSize: '1.4rem'}
               
              });

    };
    const handleRemoveClick = async (task, index, index2, indexDriver) => {
        //Créez une référence au document
        const docRef = doc(db, "Planning", task.id_doc);
        // Obtenez le document actuel
        const docSnap = await getDoc(docRef);
        
        const newTask = {
            ...docSnap.data()
        }

        newTask.shifts[index].shifts_available[index2].drivers.splice(indexDriver, 1);
        console.log(newTask)
        // Mettez à jour le document avec le tableau de drivers mis à jour
            await updateDoc(docRef, {
                shifts: newTask.shifts
            });
            enqueueSnackbar('Vous avez été retiré du planning avec succès.', { 
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
                className: 'text-center',
                style: {fontSize: '1.4rem'}
               
              });

    };

    const hasPassedWednesday = (startDate) =>{
        let date = new Date(startDate);
        let now = new Date(); // Date et heure actuelles
    
        // Trouver le prochain mercredi
        let nextWednesday = new Date(date);
        nextWednesday.setDate(date.getDate() + ((3 + 7 - date.getDay()) % 7));
    
        // Si le jour est mercredi mais avant minuit, on ajuste la date au mercredi précédent
        if (date.getDay() === 3 && date.getHours() < 24) {
            nextWednesday.setDate(nextWednesday.getDate() - 7);
        }
    
        // Réinitialiser les heures pour le comparer à minuit
        nextWednesday.setHours(0, 0, 0, 0);
    
        // Vérifier si la date et l'heure actuelles ont dépassé le mercredi à minuit
        return now >= nextWednesday;
    }
    const testt = () => {
        //False on peut se retirer sinon non
        let today = new Date("2023-11-30T00:00:00");
        console.log("A-t-on dépassé le mercredi ? " + hasPassedWednesday(today));
    }

    
  return (
    <div className="date-bar">
        <button onClick={moveBackward} className='m-0 p-0 ms-5'><FaAngleLeft color='#008080' size={22}/></button>
        <div ref={dateContainerRef} className="date-container">
            {visibleDates.map((date, index) => (
                
                <div key={index} className="date-column">
                    <div className="date-item mt-1 pb-5">
                        {date.toDateString()}
                    </div>
                    <div className="date-tasks px-3 pt-3">
                        {tasks[date.toDateString()] && tasks[date.toDateString()].map((task, taskIndex) => {
                            const correspondingShift = shifts.find(shift => shift.id === task.id);
                            const correspondingHub = hubs.find(hub => hub.id === task.id_hub);
                            if(correspondingShift){
                                return (
                                    <div key={taskIndex} className="task-item" style={{background: correspondingShift.color}}>
                                    {!task.in_progress &&
                                        <div className="d-grid gap-2 d-flex justify-content-end mb-3 me-2 mt-1">
                                        {(() => {
  if (!task?.drivers || task?.drivers.length === 0) {
    return (
      <HiHand
        color="#A9A9A9"
        style={{ cursor: "pointer", display: "inline" }}
        onClick={() => handleHandClick(task, task.index_shift, task.index_available_shift)}
      />
    );
  }

  const indexDriver = task.drivers.findIndex((driver) => driver.id === currentUser.uid);

  if (indexDriver !== -1) {
    const driver = task.drivers[indexDriver];
    if (driver.to_apply) {
      return (
        <MdCheckCircle
          color="#77DD77"
          style={{ cursor: "pointer", display: "inline" }}
          key={indexDriver}
        />
      );
    } else {
      return (
        <>
            {hasPassedWednesday(task.dateDebutPlannig.toDate()) ? 
            <HiHand
        color="#008080"
        style={{ display: "inline" }}
      /> :         <MdCancel
          color="red"
          style={{ cursor: "pointer", display: "inline" }}
          key={indexDriver}
          onClick={() => handleRemoveClick(task, task.index_shift, task.index_available_shift, indexDriver)}
        />
      }
        </>
        

      );
    }
  } else {
    return (
      <HiHand
        color="#A9A9A9"
        style={{ cursor: "pointer", display: "inline" }}
        onClick={() => handleHandClick(task, task.index_shift, task.index_available_shift)}
        key={indexDriver}
      />
    );
  }
})()}

                                        {/* {task && task?.drivers?.map((driver, indexDriver) => {
                                  
                                            if(currentUser.uid === driver.id){
                                                if(driver.to_apply){
                                                    return(
                                                        <MdCheckCircle color='#77DD77' style={{cursor: 'pointer', display: 'inline'}} key={indexDriver}/>
                                                    )
                                                }else{
                                                    return (
                                                    <MdCancel color='red' style={{cursor: 'pointer', display: 'inline'}}     

                        key={indexDriver}/>
                                                )
                                                }
                                                
                                            }else{
                                                return (
                                                    <HiHand color='#d3c9b8' style={{cursor: 'pointer', display: 'inline'}} onClick={() => handleHandClick(task, task.index_shift, task.index_available_shift)}/>
                                                )
                                                
                                            }
                                        })} */}
                                        </div>
                                    }

                                        <p className='m-0 p-0 mt-2' style={{fontWeight: '500'}}>{correspondingShift.type}</p>
                                        <p className='m-0 p-0' style={{fontWeight: '500'}}>{correspondingShift.start} - {correspondingShift.end}</p>

                                        <p className='m-0 p-0 mt-3' style={{fontWeight: '500'}}>{correspondingHub.name}</p>
                                        <p className='m-0 p-0 mb-3 text-secondary fst-italic' style={{fontSize: '1.4rem'}}><FaMapMarkerAlt className='mb-1 me-1'/>{correspondingHub.address}</p>
                                        {task.drivers && task.drivers.length > 0 && task.drivers.map((driver, indexDriver) => {
                                            if(currentUser.uid === driver.id && driver.to_apply){
                                                return (
                                                    <p className=' p-2 rounded bg-light' key={indexDriver}>
                                                        <MdCheckCircle className='mb-1 me-1' color='#77DD77'/>{capitalizeName(customerData?.firstName)} {customerData?.lastName.toUpperCase()}
                                                    </p>
                                                )
                                            }else{
                                                return <></>
                                            }
                                        })}
                                        <div className="profile-container">
                                            <div className="profile-icon">
                                                <FaUser color='#008080'/>
                                            </div>
                                            <p className="profile-text m-0 p-0" style={{fontWeight: '500'}}>{task.drivers ? task.drivers.length : '0'}/{task.places_available}</p>
                                        </div>
                                    </div>
                                )
                            }else{
                                return <></>
                            }
                        })}
                    </div>
                </div>
            ))}
        </div>
        <button onClick={moveForward} className='m-0 p-0 me-5'><FaAngleRight color='#008080' size={22}/></button>
        
    </div>
  )
}
